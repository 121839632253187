<template>
  <div class="img-center" :style="backCss">
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab=" 广场">
        <div class="action">
          <div style="display: flex;align-items: center;">
            <a-radio-group @change="radioChange" v-model="radioValue">
              <a-radio :value="1">全部</a-radio>
              <a-radio :value="2">仅查看未上过首页的图片</a-radio>
              <a-radio :value="3">仅查看上过首页的图片</a-radio>
            </a-radio-group>
          </div>
          <div><a-button type="primary" @click="addImg">上传</a-button></div>
        </div>
        <a-spin :spinning="loading">
          <div style="overflow-x:auto;">
            <virtual-list
              v-if="squareImgs.length"
              style="height: calc(100vh - 305px); overflow-y: auto;min-width:1358px;padding-bottom:20px;"
              class=""
              @scroll="squareImgScroll"
              :data-key="'key'"
              :data-sources="squareImgs"
              :estimate-size="300"
              :data-component="itemComponent"
              :keeps="20"
              :extra-props="{
                itemClick: itemClick,
                current: current
              }"
            />
            <a-empty v-else :image="simpleImage" />
          </div>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2" tab="图片管理">
        <img-center-table @addImg="addImg" @editImg="editImg" ref="imgTable" />
      </a-tab-pane>
    </a-tabs>
    <a-modal :title="title" :visible="visible" @ok="handleImgOk" @cancel="handleImgCancel">
      <a-form-model ref="form" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item class="upload-item" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="url">
          <span slot="label">
            图片：
            <div style="font-size:12px;color: rgba(0, 0, 0, 0.25);">4：3比例的横版图片</div>
          </span>
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :beforeUpload="beforeUpload"
            @change="
              info => {
                handleChangeUpload(info, 'cover')
              }
            "
          >
            <img v-if="formData.url" :src="formData.url" style="width:240px;height:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="标题"
          class="first-form-item"
          prop="title"
        >
          <a-input v-model="formData.title" placeholder="请输入标题" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { postAction, getAction, putAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import VirtualList from 'vue-virtual-scroll-list'
import Item from './modules/imgCenterItem'
import imgCenterTable from './imgCenterTable'
import { Empty } from 'ant-design-vue'
export default {
  mixins: [UploadFileToOssMixins],
  components: {
    VirtualList,
    imgCenterTable
  },
  data() {
    let validateCover = (rule, value, callback) => {
      if (!this.formData.url) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      radioValue: 1,
      formData: {},
      itemComponent: Item, //虚拟滚动组件循环对象
      current: '',
      squareImgs: [],
      title: '上传',
      visible: false,
      rules: {
        url: [{ validator: validateCover, message: '请上传图片', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      },
      layout: {
        labelCol: {
          span: 9
        },
        wrapperCol: {
          span: 15
        }
      },
      wrapperCol: {
        span: 16
      },
      labelCol: {
        span: 8
      },
      avatarLoading: false,
      isEdit: false,
      tab: '1',
      color: '',
      simpleImage: ''
    }
  },
  created() {
    this.getSquareImgs()
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  computed: {
    backCss() {
      /* let colorBack = this.color */
      this.color = this.$store.state.app.color
      return {
        /* '--back-color': colorBack, */
        '--theme-color': this.color
      }
    }
  },
  methods: {
    radioChange() {
      this.getSquareImgs()
    },
    editImg(record) {
      this.isEdit = true
      this.visible = true
      this.formData.url = record.img.url
      this.formData.title = record.img.title
      this.formData.id = record.img.id
    },
    squareImgScroll(event, range) {
      console.log(event, range)
    },
    itemClick() {},
    tabChange(key) {
      this.tab = key
      if (key == 2) {
        this.$nextTick(() => {
          if (this.$refs.imgTable) {
            this.$refs.imgTable.loadData()
          }
        })
      } else {
        this.getSquareImgs()
      }
    },
    async getSquareImgs() {
      this.loading = true
      let onlyOnTheFrontPage = null
      if (this.radioValue == 2) {
        onlyOnTheFrontPage = false
      } else if (this.radioValue == 3) {
        onlyOnTheFrontPage = true
      }
      const res = await getAction('/imgCenter/page?current=1&size=500', { onlyOnTheFrontPage })
      if (res.code == 200) {
        let imgs = []
        res.data.records?.forEach((item, index) => {
          if (!imgs[Math.trunc(index / 4)]) {
            imgs[Math.trunc(index / 4)] = {
              arr: [],
              key: Math.trunc(index / 4)
            }
          }
          imgs[Math.trunc(index / 4)].arr.push(item)
        })
        /*  for (var i = 1; i <= 1000; i++) {
          this.squareImgs.push({ key: i, value: i })
        }
        this.squareImgs?.forEach(item => {
          item.key = item.img.id
        }) */
        this.squareImgs = [...imgs]
        console.log(this.squareImgs)
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.message)
      }
      this.loading = false

      /* for (var i = 1; i <= 1000; i++) {
        this.squareImgs.push({key:i,value:i})
      } */
    },
    addImg() {
      this.isEdit = false
      this.visible = true
    },
    handleImgCancel() {
      this.visible = false
      this.formData = {}
    },
    handleChangeUpload(info, type) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.url = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
        this.$forceUpdate()
      }
    },
    handleImgOk() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.isEdit) {
            const res = await postAction('/imgCenter', this.formData)
            if (res.code == 200) {
              this.$message.success('上传成功')
              this.handleImgCancel()
              if (this.tab == 1) {
                this.getSquareImgs()
              } else {
                if (this.$refs.imgTable) {
                  this.$refs.imgTable.loadData()
                }
              }
            } else {
              this.$message.error(res.msg || res.message)
            }
          } else {
            const res = await putAction('/imgCenter', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.handleImgCancel()
              if (this.tab == 1) {
                this.getSquareImgs()
              } else {
                if (this.$refs.imgTable) {
                  this.$refs.imgTable.loadData()
                }
              }
            } else {
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.img-center {
  padding: 24px 48px 24px 48px;
  .action {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
  }
}
/deep/ .upload-item .ant-form-item-label > label::after {
  content: '' !important;
}
</style>
