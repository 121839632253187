var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-center", style: _vm.backCss },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" }, on: { change: _vm.tabChange } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: " 广场" } },
            [
              _c("div", { staticClass: "action" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "a-radio-group",
                      {
                        on: { change: _vm.radioChange },
                        model: {
                          value: _vm.radioValue,
                          callback: function ($$v) {
                            _vm.radioValue = $$v
                          },
                          expression: "radioValue",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: 1 } }, [
                          _vm._v("全部"),
                        ]),
                        _c("a-radio", { attrs: { value: 2 } }, [
                          _vm._v("仅查看未上过首页的图片"),
                        ]),
                        _c("a-radio", { attrs: { value: 3 } }, [
                          _vm._v("仅查看上过首页的图片"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary" }, on: { click: _vm.addImg } },
                      [_vm._v("上传")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-spin", { attrs: { spinning: _vm.loading } }, [
                _c(
                  "div",
                  { staticStyle: { "overflow-x": "auto" } },
                  [
                    _vm.squareImgs.length
                      ? _c("virtual-list", {
                          staticStyle: {
                            height: "calc(100vh - 305px)",
                            "overflow-y": "auto",
                            "min-width": "1358px",
                            "padding-bottom": "20px",
                          },
                          attrs: {
                            "data-key": "key",
                            "data-sources": _vm.squareImgs,
                            "estimate-size": 300,
                            "data-component": _vm.itemComponent,
                            keeps: 20,
                            "extra-props": {
                              itemClick: _vm.itemClick,
                              current: _vm.current,
                            },
                          },
                          on: { scroll: _vm.squareImgScroll },
                        })
                      : _c("a-empty", { attrs: { image: _vm.simpleImage } }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "图片管理" } },
            [
              _c("img-center-table", {
                ref: "imgTable",
                on: { addImg: _vm.addImg, editImg: _vm.editImg },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.title, visible: _vm.visible },
          on: { ok: _vm.handleImgOk, cancel: _vm.handleImgCancel },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "upload-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "url",
                  },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("\n          图片：\n          "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          color: "rgba(0, 0, 0, 0.25)",
                        },
                      },
                      [_vm._v("4：3比例的横版图片")]
                    ),
                  ]),
                  _c(
                    "a-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        name: "file",
                        "list-type": "picture-card",
                        "show-upload-list": false,
                        action: _vm.ossUploadUrl,
                        data: _vm.ossUploadData,
                        beforeUpload: _vm.beforeUpload,
                      },
                      on: {
                        change: (info) => {
                          _vm.handleChangeUpload(info, "cover")
                        },
                      },
                    },
                    [
                      _vm.formData.url
                        ? _c("img", {
                            staticStyle: { width: "240px", height: "180px" },
                            attrs: { src: _vm.formData.url, alt: "avatar" },
                          })
                        : _c(
                            "div",
                            [
                              _c("a-icon", {
                                attrs: {
                                  type: _vm.avatarLoading ? "loading" : "plus",
                                },
                              }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("点击上传"),
                              ]),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "标题",
                    prop: "title",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }